import { Component, Inject, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, filter } from 'rxjs';
import { AccountInfo } from '@azure/msal-browser';
import { NavigationStart, Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})

export class NavbarComponent implements OnInit {
  userImage$:Observable<string>;
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  user:any;
  username: any;
  editUrl: '/profile';
  
  public isAuthenticated$!: Observable<boolean>;
  isAuthenticated: boolean = false;
  accessToken: string | undefined;
  userInfo: any;
  userEmail: any;
  idToken: any;

  env = environment.type != "prod" ? environment.type : ""

  constructor(config: NgbDropdownConfig, private http: HttpClient, private router: Router, private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
    this.accessToken = this._oktaAuth.getAccessToken();

    config.placement = 'bottom-right';
    this.getAccountInfo();
  }

  async getAccountInfo() {
    this.userInfo = await this._oktaAuth.getUser();

    if (this.userInfo) {
      this.username = this.userInfo.name;

      localStorage.setItem("email", this.userInfo.email);
      localStorage.setItem("loggedInUserName", this.username);
    } else {
      console.error('ID token not found.');
    }
  }


  ngOnInit() {

  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  logout(): void {
    this._oktaAuth.signOut();
  }

}
