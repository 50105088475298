<div class="page-header">
    <h3 class="page-title font">
        Profile
    </h3>
</div>

<div class="row">
    <div class="col-lg-12 grid-margin" style="display: block;">
        <div class="card">

          <!-- display the information of the user -->
            <div class="info-container card-body">
                <div class="info-row">
                  <h4 class="info-label">Email Address:</h4>
                  <h4 class="info-value">{{email}}</h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">First Name:</h4>
                  <h4 class="info-value">{{fname}}</h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">Last Name:</h4>
                  <h4 class="info-value">{{lname}}</h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">Employee Number:</h4>
                  <h4 class="info-value">{{empNum}}</h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">Company Name:</h4>
                  <h4 class="info-value">{{compName}}</h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">Company ID:</h4>
                  <h4 class="info-value">{{compID}}</h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">Location Address:</h4>
                  <h4 class="info-value" [innerHTML]="locAddr"></h4>
                </div>
                <div class="info-row">
                  <h4 class="info-label">Location ID:</h4>
                  <h4 class="info-value">{{locID}}</h4>
                </div>
              </div>
              
        </div>
    </div>
</div>

