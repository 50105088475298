import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'admin-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrl: './employee-profile.component.scss'
})
export class EmployeeProfileComponent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  info:any;
  apps:[];
  data:any[];
  gridData: any[];
  appNames = new Map<string, string>();

  module = 'admin';
  view = 'view';
  viewUrl = "/employee/:id/locations";
  locations: any[];

  colData = [
    { field: "location_address", header: 'Address' },
    { field: "location_id", header: 'Location ID' },
    { field: "company_name", header: 'Company' },

  ]

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private service: AdminService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.getInfo()
    .then (() => {
      this.getEmployeePermissions();
    })
  }

  // gets the user's information
  getInfo(): Promise<void> {
    this.getAppNames()
    return new Promise((resolve, reject) => {
      this.service.getEmployeeInfo(this.id).subscribe((res: any) => {
        console.log(res.data);
        this.info = res.data[0]; 
        this.email = this.info.email;
        this.firstName = this.info.first_name;
        this.lastName = this.info.last_name;
        resolve();
        console.log(this.info);
      });
       
      });
  }

  // gets the employee permissions for the user
  getEmployeePermissions()  {
    if(this.email) {
      this.service.getEmployeePermissions(this.email).subscribe((res: any) => {
        this.locations = res.locations; 
        console.log(res);
        console.log(this.locations)
      });
    }
      
  }


  getAppIds() {
    const appIds = new Set<string>();
    this.info.forEach(location => {
      console.log("location1", location.apps);
      Object.keys(location.apps).forEach(appId => appIds.add(appId));
    });
    console.log("app ids", appIds)
    return Array.from(appIds);
  }


  getLocationsForApp(appId: string) {
    console.log(`appId:`, appId);
    this.gridData = [];
    console.log("gridData", this.gridData);
    this.data = this.info.map(location => ({
      AppID: appId,
      company_name:location.company_name,
      location_address: location.location_address,
      location_id: location.location_id,
      can_manage: location.apps[appId]?.can_manage ? 'Yes' : 'No',
      is_approver: location.apps[appId]?.is_approver ? 'Yes' : 'No',
    }));
    this.gridData = this.data;
    let newObjects: {
      AppID:string,
      companyName: string
      locationAddress: string,
      locationId: string,
      canManage: string, 
      isApprover: string, 
    } [] = [];
    this.gridData.forEach((obj: any) => {
      let newObj = { 
        AppID: appId,
        companyName:obj.company_name,
        locationAddress: obj.location_address,
        locationId: obj.location_id,
        canManage: obj.can_manage, 
        isApprover: obj.is_approver, 
      };
      newObjects.push(newObj);
    });
    this.gridData = newObjects;
    console.log("gridData", this.gridData);
    return this.gridData;
  }

  // to get the app id and name.
  getAppNames() {
      this.service.findAppNames().subscribe((res: any) => {
        res.data.forEach(row => {
            const appID = row.AppId;
            const appName = row.Name;
            this.appNames.set( appID, appName );
        });
        console.log("appnames", this.appNames);
      });
  }

  convertToString(value: unknown): string {
    return String(value);
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }
}

