<div class="page-header">
    <h3 class="page-title font">
        Request Access
    </h3>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <h4>As a user, you can request access to the location and application to either approve data changes,
                                    manage data, or simply view it in a read-only format. By requesting this access, you empower
                                    yourself to make necessary updates and modifications to your data if you choose to manage it,
                                    ensuring it remains accurate and up-to-date. Alternatively, read-only access allows you to view data
                                    without making any changes.</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="submitted">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="modal-sm-err alert alert-info">
                                    <h4 style="color: green;">The request has been submitted and is currently awaiting approval</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Locations dropdown -->
                <div class="row">
                    <div class="col-md-6 no-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4><b>Select Locations</b></h4>
                                <ng-multiselect-dropdown 
                                    [placeholder]="'Select a location'"
                                    [settings]="dropdownSettings" 
                                    [data]="dropdownList" 
                                    [(ngModel)]="selectedLocations"
                                    (onSelect)="onLocationSelect($event)"
                                    (onSelectAll)="onLocationSelectAll($event)"
                                    (onDeSelect)="onLocationDeSelect($event)"
                                    (onDeSelectAll)="onLocationDeSelectAll($event)">
                                </ng-multiselect-dropdown>

                                <div *ngIf="locationEmpty" class="modal-sm-err">
                                    Please select at least one location
                                </div>

                                <br>
                                <br>
                                <h4 ><b>Please select additional permissions you would like to request access to</b></h4>
                                <br />
                                <div>
                                    <label>
                                        <input class="col-form-label mr-4 required" type="checkbox" name="view" checked disabled> View Only
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input class="col-form-label mr-4 required" type="checkbox" name="manage" [(ngModel)]="manage" (change)="onChangeManage($event)" #checkbox2> Manage Data Changes
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input class="col-form-label mr-4 required" type="checkbox" name="approver" [(ngModel)]="approver" (change)="onChangeApprover($event)" #checkbox3> Approver
                                    </label>
                                </div>
                                
                                

                            </div>
                        </div>
                    </div>
                    
                    
                    <!-- Apllications Checkbox -->
                    <div class="col-md-6 no-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4><b>Select Applications</b></h4>
                                <app-checkbox-tree  [preselectedId]="AppID" (selectionChange)="onSelectionChange($event)"></app-checkbox-tree>
                                
                                <div *ngIf="appEmpty" class="modal-sm-err">
                                    Please select at least one Application
                                </div>

                                <!-- <div [formGroup]="assetForm">
                                    <div formArrayName="appId" *ngFor="let item of apps; let i = index" >
                                        <ng-container *ngIf="item.AppId !== 'APP-000001'">
                                            <label>
                                                <input type="checkbox" [value]="item.AppId"
                                                    (change)="onAppChange(item.AppId)"
                                                    [checked]="isSelected(item.AppId)" />
                                                ({{item.AppId}}) - {{item.Name}}
                                            </label>
                                        </ng-container>
                                    </div>
                                </div>
                                <br>
                                <div>
                                    <a href="#" (click)="toggleSelectAll($event)">
                                        {{ areAllSelected() ? 'Deselect All' : 'Select All' }}
                                    </a>
                                </div>
                                <div *ngIf="isFormTouched && (assetForm.controls.appId.errors || selectedApps.size === 0)" class="modal-sm-err">
                                    Please select at least one application
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div>
                                    <button class="btn btn-success mr-2 buttonColor edges" type="submit" (click)="onSubmit()">Request Access</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>