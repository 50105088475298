import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-admin',
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.scss'
})
export class LocationsComponent implements OnInit {
  gridData: any[];
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private service: AdminService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  module = 'admin';
  view = 'view';
  viewUrl = "/locations";

  colData = [
  
    { field: "CompanyName", header: 'Company Name' },
    { field: "locationID", header: 'Location ID' },
    { field: "locationAddress", header: 'Location Address' }

  ]

  ngOnInit(): void {
    this.getLocations();
  }

  // get locations assigned to the user
  getLocations() {
    this.service.findAllLocations().subscribe((res: any) => {
      this.gridData = res.locations;
      console.log("locations Data", this.gridData)
      let newObjects: {
        CompanyName: string,
        locationID: string, 
        locationAddress: string, 
      } [] = [];

      this.gridData.forEach((obj: any) => {
        let newObj = { 
          CompanyName: obj.company_name,
          locationID: obj.location_id, 
          locationAddress: obj.location_address, 
        };
        newObjects.push(newObj);
      });

      this.gridData = newObjects;
      console.log("Res", this.gridData)
    })
  }

}
