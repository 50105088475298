import { Component } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent {
  email = '';
  fname = '';
  lname = '';
  empNum = '';
  compName = '';
  compID = '';
  locAddr = '';
  locID = '';
  info: any;
  address: any;

  constructor(private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private notifier: NotifierService,
    private service: AdminService) {
  }

  ngOnInit(): void {
    this.getInfo();
  }

  //  get the information of the user
  getInfo() {
    this.service.getInfo().subscribe((res: any) => {
      console.log(res.data);
      this.info = res.data[0]; 
      this.email = this.info.email;
      this.fname = this.info.first_name;
      this.lname = this.info.last_name;
      this.empNum = this.info.employee_number;
      this.compName = this.info.CompanyName;
      this.compID = this.info.CompanyId;
      if (this.info.LocationId == null) {
        this.locAddr = "Not Defined";
        this.locID = "Not Defined";
      }
      else {
        this.service.getAddress(this.locID).subscribe((res: any) => {
          this.address = res.addr[0];
          this.locAddr = this.address.Street + "\n" + this.address.City + '\n' + this.address.State + '\n' + this.address.PostalCode + '\n' + this.address.Country + '\n' + "County: " + this.address.County;
        })
      }
    });
  }
}
