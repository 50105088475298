import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';

type GridDataItem = [string, any];

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  providers: [DatePipe],
  styleUrl: './approval.component.scss'
})


export class ApprovalComponent {
  userInput: string = '';
  permissions: string = '';
  record: any;
    
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private notifier: ToastrService,
    private service: AdminService,
    private router: Router,
    private renderer: Renderer2,
    private location: Location,
    private el: ElementRef
  )  {this.pageNumbers = this.generatePageNumbers(1, 10);}

  gridData: GridDataItem[] = [];
  data: any;
  tempdata: any[] = [];
  employeeid: any;
  appNames = new Map<string, string>();
  currentUrl: any;
  viewUrl = "/pendingRequests";
  noContent: boolean = false;
  filteredData: GridDataItem[] = [];
  searchValue: string = '';
  entries: any[] = []

  pageNumbers: number[] = [];
  pageNo: number = 1;
  pageSize: string = '20';
  pages: number[];
  totalRecords:number;

  ngOnInit(): void {
    this.getRequests();
  }

  // to get the lists of requests for the table
  getRequests() {
    console.log("page size", this.pageSize);
    this.entries = []; 
    this.service.findRequests([0,1], this.pageNo, this.pageSize, this.searchValue).subscribe((res: any) => {
      if (res == undefined) {
        this.noContent = true;
        return;
      }
      this.data = res.data; 
      this.totalRecords = res.totalRecords;
      this.pages = Array(res.pages).fill(1).map((x,i)=>i+1);

      // Converting the entire payload to JSON type
      // And formating information
      this.data.forEach(obj => {
        obj.Value =  JSON.parse(obj.Value);
        obj.State = obj.State ? obj.State  : ''
        obj.Permissions =  ""
        
        if (  obj.Category == 1 && 'permissions' in obj.Value ) {
          console.log("HERE")
          if ('CanManage' in obj.Value.permissions && obj.Value.permissions.CanManage == 1) {
            obj.Permissions = 'manage permission';
          } else if ('IsApprover' in obj.Value.permissions && obj.Value.permissions.IsApprover == 1) {
            obj.Permissions = 'approver permission';
          }else {
            obj.Permissions = 'view permission';
          }
        }
      })

      this.entries = []
      this.data.forEach(obj => {
        obj.Header = true
        obj.self = true;
        if ( obj.Category == 0 ) {
          if (obj.CreatedBy.toLowerCase() == localStorage.getItem("email").toLowerCase()) {
            obj.self = false;
          }
          this.entries.push(obj)

          obj = Object.assign({}, obj)
          obj.Header = false

          obj.Params = Object.entries(obj.Value.create || obj.Value.update)
          this.entries.push(obj)  

          if (obj.Value.Value &&  Object.entries(obj.Value.Value).length != 0) {
            obj.ParamsValue = Object.entries(obj.Value.Value)
          }
          
        } else {
          if (obj.CreatedBy.toLowerCase() == localStorage.getItem("email").toLowerCase()) {
            obj.self = false;
          }
          this.entries.push(obj)
        }
      })

      console.log('DATA',this.entries, this.entries.length)
    });
  }

  // when approve or rejct is click send the data to the backend side
  onClick(Record: object, Status: any) {
    const data = {
      id: Record['Id'],
      tableName: Record['TableName'],
      primaryKeyName: Record['PrimaryKeyName'],
      primaryKeyValue: Record['PrimaryKeyValue'],
      value: Record['Value'],
      employeeID: Record['EmployeeId'],
      appID: Record['AppId'],
      locationID: Record['LocationId'],
      category: Record['Category'],
      columnName:  Record['ColumnName'],
      status: Status
    };
    const jsonString = JSON.stringify(data);
    console.log(jsonString);
    const type = { success: "success", error: "error" };
    if (Record['Category'] == 1) {
      this.service.changeStatus(jsonString).subscribe({
        next: data => {
          this.notifier.success(data.message);
          this.router.navigate(["/pendingRequest"]);
          window.location.reload();
        },
        error: error => {
          console.log(error);
          this.notifier.error(type.error, error.error);
        }
      });
    }
    else {
      // Data change for tags application
      console.log("tablename", Record['TableName']);
      if (Record['TableName'] == "[tags].[List]") {
        this.service.changeStatusTags(jsonString).subscribe({
          next: data => {
            this.notifier.success(data.message);
            this.router.navigate(["/pendingRequest"]);
            window.location.reload();
          },
          error: error => {
            console.log(error);
            this.notifier.error(type.error, error.error);
          }
        });
      }

      //  Data change for other apps
      else {
        this.service.changeStatusDataChange(jsonString).subscribe({
          next: data => {
            this.notifier.success(data.message);
            this.router.navigate(["/pendingRequest"]);
            window.location.reload();
          },
          error: error => {
            console.log(error);
            this.notifier.error(type.error, error.error);
          }
        });
      }
      
    }
    
  }

  // Search function
  onClickSearch() {
    this.pageNo = 1;
    this.searchValue = this.searchValue.replace("'","").replace('"',"")
    console.log(this.searchValue)
    this.getRequests();
  }

  /* Function to clear the search keyword */
  onClickReset() {
    this.pageNo = 1;
    this.searchValue = "";
    console.log(this.searchValue)
    this.getRequests();
  }

  // to move to the top of the screen when loading the page
  scrollTop(){
    const duration = 500; // Duration of the scroll animation in milliseconds
    const start = window.pageYOffset || document.documentElement.scrollTop;
    const startTime = performance.now();

    const easeInOutCubic = (t: number) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };

    const scroll = (timestamp: number) => {
      const currentTime = timestamp - startTime;
      const progress = easeInOutCubic(Math.min(currentTime / duration, 1));

      window.scrollTo(0, start * (1 - progress));

      if (currentTime < duration) {
        window.requestAnimationFrame(scroll);
      }
    };

    window.requestAnimationFrame(scroll);
  }

  /*  Functions for the Pagination bar */

  // Executes this function when a page number is selected from the dropdown
  onClickPageNumber(event: any) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    let page = parseInt(value);
    console.log(page, this.pages.length)
    if ( page > 0 && page <= this.pages.length) {
      this.pageNo = page
      this.getRequests();
    }
    this.scrollTop();
  }

  // Handles navigation to the next or previous page when the corresponding button is clicked
  onClickNextPrev(page: any) {
    console.log(page, this.pages.length)
    if ( page > 0 && page <= this.pages.length) {
      this.pageNo = page
      this.getRequests();
    }
    this.scrollTop();
  }

  // Exexutes when the page size is selected from the dropdown
  onSelectPageSize(event: any) {
    this.pageNo = 1;
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    this.pageSize = value;
    this.getRequests();
    this.scrollTop();
  }

  // Finds the starting record number for each page
  calculateStartingRecord(): number {
    return (this.pageNo - 1) * parseInt(this.pageSize) + 1;
  }

  //  Finds the ending record number for each page
  calculateEndingRecord(): number {
    const lastRecord = this.pageNo * parseInt(this.pageSize);
    return Math.min(lastRecord, this.totalRecords);
  }

  generatePageNumbers(start: number, end: number): number[] {
    const numbers = [];
    for (let i = start; i <= end; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  /* Function for pop up when reject is clicked */
  openPopup(Record: object) {
    this.record = Record;
    const popup = document.getElementById('popup');
    if (popup) {
      popup.style.display = 'block';
    }
  }

  closePopup() {
    const popup = document.getElementById('popup');
    if (popup) {
      popup.style.display = 'none';
    }
  }

  saveText() {
    console.log('User input:', this.userInput);
    let existingValue = this.record['Value']; // Parse existing JSON string

    // Add rejectReason to existingValue
    existingValue.rejectReason = this.userInput;

    // Convert back to JSON string
    this.record['Value'] = JSON.stringify(existingValue);

    console.log('Updated Value:', this.record['Value']);
    this.onClick(this.record,3);

  }

}
