<div class="page-header">
    <h3 class="page-title font">
        Employees
    </h3>
</div>


<div class="row">
    <div class="col-lg-12 grid-margin" style="display: block;">
        <div class="card">
            <div class="card-body">
                
                <!-- The Search Component -->
                <div class="form-group">
                    <div class="input-group">
                        <input type="text" class="form-control font" placeholder="Search Keywords"  [(ngModel)]="searchValue">
                        <div>
                            <button class = "btn btn-sm buttonColor edges" (click)="onClickSearch()" type="button">Search</button>
                            <button class = "btn btn-sm buttonColor" style = "margin-left: 5px;" (click)="onClickReset()" type="button">Reset</button>
                        </div>
                    </div>
                </div>

                <!-- display list of employees -->
                <div class="row">
                    <div class="col-sm-12 container font">
                        <app-data-table [GridData]="gridData" [ColData]="colData"></app-data-table>
                    </div>
                </div>

                <!-- Pagination bar -->
                <div class="pagination-bar">
                    <div class="pagination-controls">
                        <span class="font" style="font-size: 15px;">Go to:</span>
                        <select class="page-number-dropdown"  [(ngModel)]="pageNo" (change)="onClickPageNumber($event)">
                            <option [ngClass]="pageNo == page ? 'page-item active' : 'page-item'" *ngFor="let page of pages" [value]="page">{{ page }}</option>
                        </select>
                        <span class ="font" style="font-size: 15px;">Show rows:</span>
                        <select class="page-size-dropdown"  (change)="onSelectPageSize($event)">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span class="showing-records font">{{ calculateStartingRecord() }}-{{ calculateEndingRecord() }} of {{ totalRecords }} records</span>
                        <button class="prev-button font" (click)="onClickNextPrev(pageNo-1)">&lt;</button>
                        <button class="next-button font" (click)="onClickNextPrev(pageNo+1)">&gt;</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
