import { Component, Input, ElementRef, ViewChild, QueryList } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { CheckboxTreeComponent } from '../checkbox-tree/checkbox-tree.component';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrl: './request.component.scss'
})
export class RequestComponent {

  @ViewChild(CheckboxTreeComponent) checkboxTree: CheckboxTreeComponent;

  dropdownSettings: IDropdownSettings;
  dropdownList = [];
  selectedLocations: any[] = [];


  @ViewChild('checkbox2') checkbox2: ElementRef;
  @ViewChild('checkbox3') checkbox3: ElementRef;

  @Input() id: any;
  submitted = false;
  locationEmpty = false;
  appEmpty = false;
  location:any;
  AppID: any

  manage = false;
  approver = false;

  // When manage checkbox is unchecked or checked
  onChangeManage(event) {
    this.manage = event.target.checked;
    console.log("manage", this.manage);
  }

  // When approver checkbox is unchecked or checked
  onChangeApprover(event) {
    this.approver = event.target.checked;
    console.log("approver", this.approver);
  }

  selectedChildIds: string[] = []; // Store selected child node IDs

  // Method to handle selection change from the tree component
  onSelectionChange(selectedIds: string[]): void {
    this.selectedChildIds = selectedIds; // Update the selected IDs
    console.log('Selected Child IDs:', this.selectedChildIds); // Output the selected IDs
    this.appEmpty = false;
  }

  constructor(private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private service: AdminService,
    private notifier: ToastrService,
    private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.location = params['locationId'];
      this.AppID = params['appId']
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }


  groups : any;
  companies: any;
  locations: any;
  apps: any;
  selectedApps: Set<string> = new Set<string>();
  isFormTouched = false;

  assetForm: FormGroup = this.fb.group({
    appId: this.fb.array([], Validators.required),
  });

  ngOnInit(): void {
    console.log(this.AppID);
    console.log(this.location);
    this.service.findAppNames().subscribe((res: any) => {
      this.apps = res.data;
      console.log("apps", this.apps);
    });

    if (this.AppID) {
      this.selectedApps.add(this.AppID);
      this.isFormTouched = false;
    }
    
    // to get all the location for the location selector dropdown
    this.service.getAllLocations().subscribe((res: any) => {
      var dropdownList = []
      for (var location of res.data) {
        const state = location.State ? `${location.State} ` : '';
        dropdownList.push({
          item_id: location.LocationId,
          item_text: `${location.LocationId} - ${location.CompanyName}, ${location.Street} ${location.City} ${state}`
        })
      }
      this.dropdownList = dropdownList
      console.log(this.dropdownList.length);
      if (this.location) {
        const item = this.dropdownList.find(option => option.item_id === this.location);
        console.log(item);
        if (item) {
          this.selectedLocations = [item];
          console.log(this.selectedLocations)
        }
      }
    });
  }

  onLocationSelect(location: any) {
    console.log('Selected Items:', this.selectedLocations);
    this.locationEmpty = false;
  }

  onLocationDeSelect(location: any) {
    console.log('Selected Items:', this.selectedLocations);
  }

  onLocationSelectAll(items: any) {
    console.log('Selected Items:', this.selectedLocations);
    this.locationEmpty = false;
  }

  onLocationDeSelectAll(items: any) {
    console.log('Selected Items:', this.selectedLocations);
  }

  // get selected apps
  get appIdFormArray(): FormArray {
    return this.assetForm.get('appId') as FormArray;
  }

  // to check if any application checkbox is selected
  isSelected(appId: string): boolean {
    return this.selectedApps.has(appId);
  }

  // For any changes in the application checkbox
  onAppChange(appId: string): void {
    this.isFormTouched = true;

    if (this.selectedApps.has(appId)) {
      this.selectedApps.delete(appId);
    } else {
      this.selectedApps.add(appId);
    }

    this.updateFormArray();
  }

  // For select all and deselct all for the application checkboxes
  toggleSelectAll(event: Event): void {
    event.preventDefault();
    if (this.areAllSelected()) {
      this.selectedApps.clear();
    } else {
      this.apps.forEach(app => this.selectedApps.add(app.AppId));
    }

    this.updateFormArray();
  }

  // Check if all applications are selected
  areAllSelected(): boolean {
    return this.apps && this.selectedApps.size === this.apps.length;
  }

  // to update the checkboxes
  updateFormArray(): void {
    const appIdArray = this.assetForm.get('appId') as FormArray;
    appIdArray.clear();
    this.selectedApps.forEach(appId => appIdArray.push(this.fb.control(appId)));
  }

  get formValues() { return this.assetForm.controls; }

  // function that runs when the form is submitted
  onSubmit() {
    console.log("selectedLocations", this.selectedLocations);
    console.log("selectedChildIds", this.selectedChildIds);
    if (this.selectedLocations.length == 0 || this.selectedChildIds.length == 0) {
      if (this.selectedLocations.length == 0) 
        this.locationEmpty = true;
      if (this.selectedChildIds.length == 0)
        this.appEmpty = true;
      return;
    }
    

    this.submitted = true;
    console.log(this.assetForm.value.locationId);
    console.log(this.selectedChildIds);
    console.log(this.approver);
    console.log(this.manage);
    const type = { success: "success", error: "error" };
    // For each of the locations selected
    this.selectedLocations.forEach(item => {
      // if approver checkbox is selected
      if (this.approver) {
        // if location and app id is passed as param
        // if (this.location != null && this.AppID != null) {
        //   var formV = JSON.stringify({
        //     Category: 1,
        //     TableName: "[acl].[AccessPermissions]",​
        //     Value: {"permissions": {"IsApprover": 1}},​
        //     LocationId: this.location,
        //     AppId: this.AppID
        //   });
  
        //   this.service.create(formV).subscribe({
        //     next: data => {
        //       this.notifier.success(data.message);
        //       this.router.navigate(['requestAccess']);
        //     },
        //     error: error => {
        //       console.log(error);
        //       this.notifier.error(type.error, error.error);
        //     }
        //   });
        // }
        
        // for each of the applications
        for (let i = 0; i < this.selectedChildIds.length; i++) {
          if (this.selectedChildIds[i] == "APP-000001") {
            continue;
          }
          console.log("testing check box", this.selectedChildIds[i])
          var formV = JSON.stringify({
            Category: 1,
            TableName: "[acl].[AccessPermissions]",​
            Value: {"permissions": {"IsApprover": 1}},​
            LocationId: item.item_id,
            AppId: this.selectedChildIds[i]
          });
          this.service.create(formV).subscribe({
            next: data => {
              this.notifier.success(data.message);
              this.router.navigate(['requestAccess']);
            },
            error: error => {
              console.log(error);
              this.notifier.error(type.error, error.error);
            }
          });
        } 
      }

      // if manage checkbox is selected
      if (this.manage) {
        // location and app id is passed as params
        // if (this.location != null && this.AppID != null) {
        //   var formV = JSON.stringify({
        //     Category: 1,
        //     TableName: "[acl].[AccessPermissions]",​
        //     Value: {"permissions": {"CanManage": 1}},​
        //     LocationId: this.location,
        //     AppId: this.AppID
        //   });
  
        //   this.service.create(formV).subscribe({
        //     next: data => {
        //       this.notifier.success(data.message);
        //       this.router.navigate(['requestAccess']);
        //     },
        //     error: error => {
        //       console.log(error);
        //       this.notifier.error(type.error, error.error);
        //     }
        //   });
        // }
        // for each of the applications is selected
        for (let i = 0; i < this.selectedChildIds.length; i++) {
          if (this.selectedChildIds[i] == "APP-000001") {
            continue;
          }
          console.log("testing check box", this.selectedChildIds[i])
          var formV = JSON.stringify({
            Category: 1,
            TableName: "[acl].[AccessPermissions]",​
            Value: {"permissions": { "CanManage": 1}},​
            LocationId: item.item_id,
            AppId: this.selectedChildIds[i]
          });
          this.service.create(formV).subscribe({
            next: data => {
              this.notifier.success(data.message);
              this.router.navigate(['requestAccess']);
            },
            error: error => {
              console.log(error);
              this.notifier.error(type.error, error.error);
            }
          });
        }
      }

      // for view option
      if (!this.manage && !this.approver) {
        // if (this.location != null && this.AppID != null) {
        //   var formV = JSON.stringify({
        //     Category: 1,
        //     TableName: "[acl].[AccessPermissions]",​
        //     Value: {"permissions": {"CanView": 1}},​
        //     LocationId: this.location,
        //     AppId: this.AppID
        //   });
  
        //   this.service.create(formV).subscribe({
        //     next: data => {
        //       this.notifier.success(data.message);
        //       this.router.navigate(['requestAccess']);
        //     },
        //     error: error => {
        //       console.log(error);
        //       this.notifier.error(type.error, error.error);
        //     }
        //   });
        // }
       
        //  for each of the applications
        for (let i = 0; i < this.selectedChildIds.length; i++) {
          if (this.selectedChildIds[i] == "APP-000001") {
            continue;
          }
          console.log("testing check box", this.selectedChildIds[i])
          var formV = JSON.stringify({
            Category: 1,
            TableName: "[acl].[AccessPermissions]",​
            Value: {"permissions": {"CanView": 1}},​
            LocationId: item.item_id,
            AppId: this.selectedChildIds[i]
          });
          this.service.create(formV).subscribe({
            next: data => {
              this.notifier.success(data.message);
              this.router.navigate(['requestAccess']);
            },
            error: error => {
              console.log(error);
              this.notifier.error(type.error, error.error);
            }
          });
        }
        
      }
    })
    console.log("Submitted Form",this.assetForm.value);
  
  this.resetForm();

  }

  // to clear the form
  resetForm() {
    this.isFormTouched = false;
    this.selectedApps.clear();
    this.selectedLocations = [];

    this.manage = false;
    this.approver = false;
    this.checkbox2.nativeElement.checked = false;
    this.checkbox3.nativeElement.checked = false;

    this.assetForm.get('locationId')?.reset('');
    this.assetForm.get('locationId')?.markAsUntouched();

    this.assetForm.get('groupId')?.reset('');
    this.assetForm.get('groupId')?.markAsUntouched();

    this.assetForm.get('companyId')?.reset('');
    this.assetForm.get('companyId')?.markAsUntouched();

    this.checkboxTree.clearSelection();

  }


  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }


}


