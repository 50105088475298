<div class="page-header">
    <h3 class="page-title font">
        Pending Requests
    </h3>
</div>

<div class="row">
    <div class="col-lg-12 grid-margin" style="display: block;">
        <div class="card">
            <div class="card-body">

                <!-- When no records found -->
                <div *ngIf="noContent" class="body">
                    <div class="alert alert-info" role="alert" style="text-align: center; padding-top: 40px; padding-bottom: 40px;">
                        <h4> There are no pending requests</h4>
                    </div>                       
                </div>

                <!-- When no records found for the serach keyword-->
                <!-- <div *ngIf="noContent && searchValue !== ''" class="body">
                    <div class="alert alert-info" role="alert" style="text-align: center; padding-top: 40px; padding-bottom: 40px;">
                        <h4> No records found matching the search keyword </h4>
                        <button onclick="location.href='/pendingRequest'" class = "btn btn-sm buttonColor edges" > Back </button>
                    </div>                       
                </div> -->
                
                <!-- search feature -->
                <div *ngIf="entries.length !== 0">

                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control font" placeholder="Search Keywords"  [(ngModel)]="searchValue">
                            <div>
                                <button class = "btn btn-sm buttonColor edges" (click)="onClickSearch()" type="button">Search</button>
                                <button class = "btn btn-sm buttonColor" style = "margin-left: 5px;" (click)="onClickReset()" type="button">Reset</button>
                            </div>
                        </div>
                    </div>

                    <!-- table for displaying requests -->

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th style="width: 1100px;" > Requests </th>
                                <th style="width: 280px;"> Date </th>
                                <th style="width: 200px;" >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <!-- displays the requests description for access permissions -->
                            <tr *ngFor="let entry of entries">
                                <td style="width: 1200px;" *ngIf="entry.Category == 1">
                                    <b>{{entry.first_name}} {{entry.last_name}}</b> 
                                    is requesting <b>{{entry.Permissions}}</b> to the 
                                    <b>{{entry.Name}}</b>
                                        application for 
                                    <b>{{entry.LocationId}}</b> 
                                    - <b> {{entry.CompanyName}}</b>,
                                    {{entry.Street}} {{entry.City}} {{entry.State}} at {{entry.CompanyId}} 
                                    
                                </td>

                                <!-- displays the requests description for data changes -->
                                <td style="width: 1200px;" *ngIf="entry.Category == 0 && entry.Header == true" >
                                    <div *ngIf="entry.Header == true">
                                        <!-- request description for creating a record -->
                                        <div *ngIf="entry.Value.create">
                                            <b>{{entry.first_name}} {{entry.last_name}}</b> 
                                            is requesting a new {{entry.Name}} at 
                                            <b>{{entry.LocationId}}</b>
                                            - <b> {{entry.CompanyName}}</b>,
                                            {{entry.Street}} {{entry.City}} {{entry.State}} at {{entry.CompanyId}} 
                                        </div>
                                        <!-- request description for updating the record -->
                                        <div *ngIf="entry.Value.update">
                                            <b>{{entry.first_name}} {{entry.last_name}}</b> 
                                            is requesting {{entry.Name}} change at 
                                            <b>{{entry.LocationId}}</b> - <b> {{entry.CompanyName}}</b>,
                                            {{entry.Street}} {{entry.City}} {{entry.State}} at {{entry.CompanyId}} - 
                                            
                                        </div>
                                    </div>
                                </td>

                                <td style="width: 1200px;" *ngIf="entry.Category == 0 && entry.Header ==false" colspan="3">
                                    <div *ngIf="entry.Header == false" class="row">
                                        <div class="col-lg-6">

                                            <!-- to display the create request table -->
                                            <table *ngIf="entry.Value.create" class = "inner-table" cellpadding="5" cellspacing="0">
                                                <caption> {{entry.Name}} Information</caption>
                                                <thead>
                                                    <tr>
                                                        <th><b>Property Name</b></th>
                                                        <th><b>Value</b></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let param of entry.Params">
                                                        <td><b> {{param[0]}} </b></td>
                                                        <td>{{ param[1] === 1 ? 'Yes' : param[1] === 0 ? 'No' : param[1] }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!-- to display the update request table -->
                                            <table *ngIf="entry.Value.update" class = "inner-table" cellpadding="5" cellspacing="0">
                                                <caption> {{entry.Name}} Change Information</caption>
                                                <thead>
                                                    <tr>
                                                        <th><b>Property Name</b></th>
                                                        <th><b>Value</b></th>
                                                        <th><b>Previous Value</b></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <td> {{ entry.ColumnName }} </td>
                                                    <td>{{ entry.Params[1][1] === 1 ? 'Yes' : entry.Params[1][1] === 0 ? 'No' : entry.Params[1][1] }}</td>
                                                    <td>{{ entry.Params[0][1] === 1 ? 'Yes' : entry.Params[0][1] === 0 ? 'No' : entry.Params[0][1] }}</td>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                        <!-- to display the tag values -->
                                        <div class="col-lg-6"  *ngIf="entry.ParamsValue">
                                            <table class = "inner-table" cellpadding="5" cellspacing="0">
                                                <caption> {{entry.Name}} Values</caption>
                                                <thead>
                                                <tr>
                                                    <th><b>Accepted Name</b></th>
                                                    <th><b>Value</b></th>
                                                    <th><b>Description</b></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let param of entry.ParamsValue">
                                                        <td>{{ param[1].CommonName }}</td>
                                                        <td>{{ param[1].AcceptedValue }}</td>
                                                        <td>{{ param[1].Explanation }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                
                                </td>
                                <td style="width: 280px;" *ngIf="entry.Header == true">
                                    {{ entry.CreatedOn  | date: 'yyyy-MM-dd HH:mm:ss' }}
                                </td>
                                <td style="width: 200px;" *ngIf="entry.Header == true">
                                    <a  *ngIf="entry.self == true || entry.super_user == 1" (click)="onClick(entry, 2)" class="mr-3" style="cursor: pointer;">Approve </a> 
                                    <a  *ngIf="entry.self == true || entry.super_user == 1" (click)="openPopup(entry)" class="mr-3" style="cursor: pointer;">Reject </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <!-- pagination bar -->
                    <div class="pagination-bar">
                        <div class="pagination-controls">
                            <span class="font" style="font-size: 15px;">Go to:</span>
                            <select class="page-number-dropdown"  [(ngModel)]="pageNo" (change)="onClickPageNumber($event)">
                                <option [ngClass]="pageNo == page ? 'page-item active' : 'page-item'" *ngFor="let page of pages" [value]="page">{{ page }}</option>
                            </select>
                            <span class ="font" style="font-size: 15px;">Show rows:</span>
                            <select class="page-size-dropdown" [(ngModel)]="pageSize" (change)="onSelectPageSize($event)">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span class="showing-records font">{{ calculateStartingRecord() }}-{{ calculateEndingRecord() }} of {{ totalRecords }} records</span>
                            <button class="prev-button font" (click)="onClickNextPrev(pageNo-1)">&lt;</button>
                            <button class="next-button font" (click)="onClickNextPrev(pageNo+1)">&gt;</button>
                        </div>
                    </div>

                    <!-- popup for reject -->
                    <div id="popup" class="popup">
                        <div class="popup-content">
                          <span class="close" (click)="closePopup()">&times;</span>
                          <p style ="font-weight: bold;"> Please enter the reason for rejecting the request: </p>
                          <textarea rows="4" cols="50" [(ngModel)]="userInput" placeholder="Type here...." style="align-items: left;"></textarea>
                          <br>
                          <button style = "align-items: center;" class="centered-button buttonColor" (click)="saveText()">Send</button>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    </div>
</div>
