import { Component, OnInit,  Output, EventEmitter, Input } from '@angular/core';
import { AdminService } from '../admin.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';

// Define interfaces for the tree structure
interface ItemNode {
  id: string;      // ID for the item
  name: string;    // Name for the item
}

interface CategoryNode {
  id: string;                    // ID for the category
  name: string;                  // Name for the category
  children?: ItemNode[];          // Children of this category
}

// Define a FlatNode for the flattened structure
interface FlatNode {
  id: string;                    // ID for the flat node
  name: string;                  // Name for the flat node
  level: number;                 // Level in the tree
  expandable: boolean;           // Whether this node can be expanded
  isSelected?: boolean;
}


@Component({
  selector: 'app-checkbox-tree',
  templateUrl: './checkbox-tree.component.html',
  styleUrl: './checkbox-tree.component.scss'
})
export class CheckboxTreeComponent implements OnInit{

  @Input() preselectedId: string | undefined;
  @Output() selectionChange = new EventEmitter<string[]>();

  private flattener: MatTreeFlattener<CategoryNode, FlatNode>;

  treeControl: FlatTreeControl<FlatNode>;          // Control for the tree structure
  dataSource: MatTreeFlatDataSource<CategoryNode, FlatNode>; // Data source for the tree

  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  constructor(private treeService: AdminService) {
    // Initialize the flattener with the transformer and the getChildren function
    this.flattener = new MatTreeFlattener<CategoryNode, FlatNode>(
      this.transformer,
      node => node.level,
      node => node.expandable,
      node => node.children || [] // Ensure it's always an array
    );

    this.treeControl = new FlatTreeControl<FlatNode>(
      node => node.level,
      node => node.expandable
    );

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.flattener);  // Create data source
  
    this.fetchTreeData(); // Fetch data from API on initialization
  }

  getLevel = (node: FlatNode) => node.level;

  async ngOnInit() {
    try {
      const data = await this.fetchTreeData(); // Wait for the data to be fetched
      console.log('Fetched Data:', data);
      
      // Now you can proceed with any logic that depends on the fetched data
      // For example, preselect a specific checkbox if needed
      if (this.preselectedId) {
        const preselectedNode = this.findNodeById(this.preselectedId);
        if (preselectedNode) {
          this.checklistSelection.select(preselectedNode);
          this.emitSelectedChildIds();
        }
      }
    } catch (error) {
      console.error('Failed to fetch tree data:', error);
    }
  }

   // Helper method to find a node by its ID
   private findNodeById(id: string): FlatNode {
    return this.treeControl.dataNodes.find(node => node.id === id);
  }

  async fetchTreeData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.treeService.findAppNames().subscribe(
        (res) => {
          console.log("app name", res.data);
          
          // Transform flat data to tree structure
          const transformedData = this.transformData(res.data);
          console.log('Transformed Data:', transformedData);
          
          // Ensure transformed data is valid before assigning it
          if (Array.isArray(transformedData)) {
            this.dataSource.data = transformedData; // Set the data for the tree
            resolve(transformedData); // Resolve with transformed data
          } else {
            console.error('Transformed data is not an array:', transformedData);
            reject(new Error('Transformed data is not an array')); // Reject with an error
          }
        },
        (error) => {
          console.error('Error fetching tree data:', error);
          reject(error); // Reject with the error from the API call
        }
      );
    });
  }
  

  // The transformer function matches the MatTreeFlattener signature
  private transformer = (node: CategoryNode, level: number): FlatNode => {
    return {
      id: node.id,
      name: node.name,
      level: level,
      expandable: !!node.children && node.children.length > 0,
      isSelected: false
    };
  };

  // Transform the flat data array into a hierarchical structure
  transformData(flatData: { AppId: string; Name: string; Category: number }[]): CategoryNode[] {
    const categoryMap = new Map<number, CategoryNode>(); // Map to hold categories

    // Define your parent categories
    const parentCategories = {
      0: 'IoT',
      1: 'Enterprise',
      2: 'Analysis',
      3: 'Lean'
    };

    flatData.forEach(item => {
      // Create or get the category node
      if (!categoryMap.has(item.Category)) {
        categoryMap.set(item.Category, {
          id: item.Category.toString(),
          name: parentCategories[item.Category] || `Category ${item.Category}`,
          children: [] // Initialize with an empty children array
        });
      }

      // Add the item to the corresponding category's children
      const itemNode: ItemNode = {
        id: item.AppId,
        name: item.Name
      };
      categoryMap.get(item.Category)!.children.push(itemNode);
    });

    // Convert categoryMap to an array
    const result: CategoryNode[] = Array.from(categoryMap.values());
    console.log('Resulting Category Nodes:', result); // Log the resulting array
    return result; // Return the transformed data
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  toggleSelection(node: FlatNode) {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.emitSelectedChildIds();
  }

  emitSelectedChildIds(): void {
    const selectedChildIds = this.checklistSelection.selected
      .filter(node => !node.expandable) // Only include child nodes
      .map(node => node.id.toString());
    this.selectionChange.emit(selectedChildIds);
  }

  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }

  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }

  }

  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
      this.emitSelectedChildIds();
  }

  getSelectedNodes() {
    console.log(this.checklistSelection.selected.filter(node => !node.expandable));
  }

  clearSelection() {
    this.checklistSelection.clear(); // Clear the selection in the checklist
    this.selectionChange.emit(this.getSelectedIds()); // Emit the current selected IDs
  }

  getSelectedIds(): string[] {
    return this.checklistSelection.selected.map(node => node.id); // Adjust according to your node structure
  }

}
