<div class="page-header">
    <h3 class="page-title font">
        Your Locations
    </h3>
</div>

<div class="row">
    <div class="col-lg-12 grid-margin" style="display: block;">
        <div class="card">
            <div class="card-body">
              <app-data-table [GridData]="gridData" [ColData]="colData" [module]="module" [accessView]="view" [ViewUrl]="viewUrl"></app-data-table>
            </div>
        </div>
    </div>
</div>


