<div class="page-header">
    <h3 class="page-title font">
        Location Permissions
    </h3>
</div>

<div class="row">
    <div class="col-lg-12 grid-margin" style="display: block;">
        <div class="card">
            <div class="card-body">
                <div class="info-container card-body">
                    
                  <div class="info-row">
                      <h4 class="info-label">Location ID:</h4>
                      <h4 class="info-value">
                        <a [href]="'https://sites.apps.marmonim.com/locations/' + id">
                          {{ id }}
                        </a>
                      </h4>
                    </div>
                    
                    <div class="info-row">
                      <h4 class="info-label">Company Name:</h4>
                      <h4 class="info-value">{{name}}</h4>
                    </div>
                    
                    <div class="info-row">
                      <h4 class="info-label">Location Address:</h4>
                      <h4 class="info-value">{{ address }}</h4>
                    </div>
                
                  </div>
              <app-data-table [GridData]="gridData" [ColData]="colData"></app-data-table>
            </div>
        </div>
    </div>
</div>

