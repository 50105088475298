

import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { SortDirection, SortEvent, rotate } from './SortEvent';

@Directive({
    selector: 'th[sortable]',
    host: {
      '[class.asc]': 'direction === "asc"',
      '[class.desc]': 'direction === "desc"',
      '(click)': 'rotate()'
    }
  })

export class NgbdSortableHeader {

  @Input() field: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.field, direction: this.direction });
  }
}
