import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { DataTableComponent } from './data-table/data-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalComponent } from './modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';


@NgModule({
  declarations: [
    ButtonComponent,
    DataTableComponent,
    ModalComponent,
    DeleteModalComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  exports:[
    ButtonComponent,
    DataTableComponent,
    ModalComponent,
    NgMultiSelectDropDownModule,
  ],
  providers:[NgbActiveModal],
  // entryComponents:[]
})
export class UiComponentsModule { 

}
