import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-admin',
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss'
})
export class EmployeesComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private service: AdminService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {this.pageNumbers = this.generatePageNumbers(1, 10);}

  pageNumbers: number[] = [];
  searchValue: string = ""

  gridData: any[];
  pageNo: number = 1;
  pageSize: string = '10';
  pages: number[];
  totalRecords:number;

  colData = [
  
    { field: "employeeID", header: 'Employee ID' },
    { field: "firstName", header: 'First Name' },
    { field: "lastName", header: 'Last Name' },
    { field: "email", header: 'Email Address' },
    { field: "companyName", header: 'Company Name' }

  ]

  ngOnInit(): void {
    this.getEmployees();
  }

  // get the list of employees
  getEmployees() {
    this.service.findEmployees(this.pageNo, this.pageSize, this.searchValue).subscribe((res: any) => {
      this.gridData = res.data;
      console.log("employees Data", this.gridData)
      this.totalRecords = res.totalRecords;
      console.log("pages", res.pages);
      this.pages = Array(res.pages).fill(1).map((x,i)=>i+1);
      console.log("this.pages", this.pages)
      let newObjects: {
        employeeID: string,
        firstName: string, 
        lastName: string, 
        email: string, 
        companyName: string
      } [] = [];

      this.gridData.forEach((obj: any) => {
        let newObj = { 
          employeeID: obj.employee_number,
          firstName: obj.first_name, 
          lastName: obj.last_name, 
          email: obj.email, 
          companyName: obj.CompanyName
        };
        newObjects.push(newObj);
      });

      this.gridData = newObjects;
      console.log("gridData", this.gridData)
    })
  }

  // to move to the top when the next or previous page is clicked
  scrollTop(){
    const duration = 500; // Duration of the scroll animation in milliseconds
    const start = window.pageYOffset || document.documentElement.scrollTop;
    const startTime = performance.now();

    const easeInOutCubic = (t: number) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };

    const scroll = (timestamp: number) => {
      const currentTime = timestamp - startTime;
      const progress = easeInOutCubic(Math.min(currentTime / duration, 1));

      window.scrollTo(0, start * (1 - progress));

      if (currentTime < duration) {
        window.requestAnimationFrame(scroll);
      }
    };

    window.requestAnimationFrame(scroll);
  }

  generatePageNumbers(start: number, end: number): number[] {
    const numbers = [];
    for (let i = start; i <= end; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  /*  Function to display the search results */
  onClickSearch() {
    this.pageNo = 1;
    this.searchValue = this.searchValue.replace("'","").replace('"',"")
    console.log(this.searchValue)
    this.getEmployees();
  }

  /* Function to clear the search keyword */
  onClickReset() {
    this.pageNo = 1;
    this.searchValue = "";
    console.log(this.searchValue)
    this.getEmployees();
  }

  /*  Functions for the Pagination bar */

  // Executes this function when a page number is selected from the dropdown
  onClickPageNumber(event: any) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    let page = parseInt(value);
    console.log(page, this.pages.length)
    if ( page > 0 && page <= this.pages.length) {
      this.pageNo = page
      this.getEmployees();
    }
    this.scrollTop();
  }

  // Handles navigation to the next or previous page when the corresponding button is clicked
  onClickNextPrev(page: any) {
    console.log(page, this.pages.length)
    if ( page > 0 && page <= this.pages.length) {
      this.pageNo = page
      this.getEmployees();
    }
    this.scrollTop();
  }

  // Exexutes when the page size is selected from the dropdown
  onSelectPageSize(event: any) {
    this.pageNo = 1;
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    this.pageSize = value;
    this.getEmployees();
    this.scrollTop();
  }

  // Finds the starting record number for each page
  calculateStartingRecord(): number {
    return (this.pageNo - 1) * parseInt(this.pageSize) + 1;
  }

  //  Finds the ending record number for each page
  calculateEndingRecord(): number {
    const lastRecord = this.pageNo * parseInt(this.pageSize);
    return Math.min(lastRecord, this.totalRecords);
  }

}
