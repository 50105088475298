import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { Observable, filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent implements OnInit{
  // public isAuthenticated$!: Observable<boolean>;
  // isAuthenticated: boolean = false;
  
  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean;

  constructor(private router: Router) {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    // router.ur.forEach((event) => { 
    //   console.log(event)
    //   if(event instanceof ActivatedRoute) {
        // console.log(this.router.url);
        // if((event['url'] == '/user-pages/login') || (event['url'] == '/user-pages/register') || (event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500') ) {
        //   this.showSidebar = false;
        //   this.showNavbar = false;
        //   this.showFooter = false;
         
        //   document.querySelector('.main-panel').classList.add('w-100');
        //   document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        //   document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg', );
        //   document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
        //   if((event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500')) {
        //     document.querySelector('.content-wrapper').classList.add('p-0');
        //   }
        // } else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          // document.querySelector('.main-panel').classList.remove('w-100');
          // document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          // document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          // document.querySelector('.content-wrapper').classList.remove('p-0');
        // }
  }

  ngOnInit() {

  }
}
