import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { NgbdSortableHeader } from './NgbdSortableHeader.1';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnInit {
  @Input() GridData: any;
  @Input() ColData: any;
  @Input() page: any;
  @Input() ViewUrl: any;
  @Input() CopyUrl: any;
  @Input() EditUrl: any;
  @Input() DeleteUrl: any;
  @Input() LocationUrl: any;
  @Input() AssetUrl: any;
  @Output() btnClick = new EventEmitter();
  @Input() module: any;
  @Input() accessView: any;
  @Input() accessEdit: any;
  @Input() accessDelete: any;
  @Input() ApproveUrl: any;
  @Input() RejectUrl: any;
  @Input() DownloadUrl: any;
  @Input() pageSize: any;

  id: any;
  totalRecords: any;
  sorting: any = '';
  filter: string;
  searchParam: string;
  searchBy: string;
  moduleName: string;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.module == 'admin') {
      this.moduleName = 'admin';
      this.searchParam = 'AdminName';
      this.searchBy = 'Admin';
    } else if (this.module == 'location') {
      this.moduleName = 'locations';
      this.searchParam = 'CompanyName';
      this.searchBy = 'Company';
    } else if (this.module == 'company') {
      this.moduleName = 'companies';
      this.searchParam = 'GroupName';
      this.searchBy = 'Group';
    }
  }

  onClick(id: any) {
    this.btnClick.emit(id);
  }

  navigateToChild(id: any, name: any, address: any) {
    this.router.navigate([this.ViewUrl, id, name, address]);
  }
  navigateToEmployeeAccess(employeeID: any, email: any) {
    this.router.navigate(['/employees', employeeID, email])
  }

  reset(){
    window.location.reload();
  }

  camelize(str: any) {
    return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
  }

  onSort(column: any) {
    this.sorting = this.sorting == "desc" ? this.sorting = "asc" : this.sorting = "desc";
    this.headers = this.ColData;
    this.headers.forEach((header: { field: any; direction: string; }) => {
      if (header.field !== column) {
        header.direction = '';
      }
    });

    let sortedData = [...this.GridData];

    this.GridData = sortedData.sort((a, b) => a[column] > b[column] ? 1 : -1);
  }

  search() {

  }
}
