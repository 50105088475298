import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.API_URL;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    })
};

@Injectable({
    providedIn: 'root'
})

export class AdminService {

  constructor(private http: HttpClient) {
  
  }

  // User's information
  getInfo(): Observable<any>{
    return this.http.get(
      API_URL + 'v1/admin/account',
      httpOptions
    )
  }

  // Get the address for a given location id
  getAddress(locationID: any): Observable<any> {
    return this.http.get(
      API_URL + 'v1/admin/address?locationID=' + locationID,
      httpOptions
    )
  }

  // get all locations that the user has access to
  findAllLocations(): Observable<any> {
    return this.http.get(
      API_URL + 'v1/auth/permissions',
      httpOptions
    )
  }

  // to get app id and names
  findAppNames(): Observable<any> {
    return this.http.get(
      API_URL + 'v1/admin/appnames',
      httpOptions
    )
  }

  // get employees at the same companies as of the user
  findEmployees(pageNum: any, pageSize: any, searchValue: any): Observable<any> {
    let searchRequest = ""
    if ( searchValue ) {
      searchRequest = "&search=" + searchValue
    }  
    return this.http.get(
      API_URL + 'v1/admin/employees?page_size='+ pageSize + '&page_num=' + pageNum + searchRequest,
      httpOptions
    )
  }

  // to get infor of an employee
  getEmployeeInfo(id: any): Observable<any> {
    return this.http.get(
      API_URL + 'v1/admin/employeeInfo?id='+ id,
      httpOptions
    )
  }

  // get an access information for an employee
  getEmployeePermissions(email: any): Observable<any> {
    let emailRequest = ""
    if (email) {
      emailRequest = "?email=" + email
    }
    return this.http.get(
      API_URL + 'v1/admin/employeePermissions'+ emailRequest,
      httpOptions
    )
  }

  findAllCompaniesByGroup(groupId): Observable<any>{
    return this.http.get(API_URL + 'v1/companies/group/' + groupId,httpOptions)
  }

  findAllLocationsByCompany(companyId: any): Observable<any>{
    return this.http.get(API_URL + 'v1/locations/company/' + companyId, httpOptions)
  }

  findAllAssetGroupsBySite(siteId: any): Observable<any>{
    siteId = siteId.toLowerCase()
    return this.http.get(API_URL + 'v1/assetGroup/site/' + siteId,httpOptions)
  }

  findAllAssetCategoriesByLocation(locationId: any): Observable<any>{
    return this.http.get(API_URL + 'v1/assetCategory/location/' + locationId,httpOptions)
  }

  findAllGroups(): Observable<any>{
    return this.http.get(API_URL + 'v1/group',httpOptions)
  }

  findOne(id: any): Observable<any>{
    return this.http.get(API_URL + 'v1/asset/view/' + id,httpOptions);
  }

  findAllAssetGroups(): Observable<any>{
    return this.http.get(API_URL + 'v1/assetGroup',httpOptions)
  }

  // to get the requests 
  findRequests(status: number[], pageNum: any, pageSize: any, searchValue: any): Observable<any>{
    let searchRequest = ""
    if ( searchValue ) {
      searchRequest = "&search=" + searchValue
    }  
    return this.http.get(API_URL + 'v1/admin/request?status=' + status + '&page_size=' + + pageSize + '&page_num=' + pageNum + searchRequest,
    httpOptions)
  }

  // to add new requests
  create(info: any): Observable<any> {
    return this.http.post(API_URL + 'v1/admin/request', JSON.parse(info))
  }

  // to get approvers
  findApprovers(appid: any, locationid: any, employeeid): Observable<any> {
    return this.http.get(API_URL + 'v1/admin/approver?appid='+appid+"&locationid="+locationid+"&employeeid="+employeeid,httpOptions)
  }

  // change the status of a access request
  changeStatus(info: any): Observable<any> {
    return this.http.put(API_URL + 'v1/admin/request', JSON.parse(info))
  }

  // change the status of a data change request
  changeStatusTags(info: any): Observable<any> {
    return this.http.put(API_URL + 'v1/tags/request', JSON.parse(info))
  }

  changeStatusDataChange(info: any): Observable<any> {
    return this.http.put(API_URL + 'v1/admin/ApproveReject', JSON.parse(info))
  }

  // get all locations for the location dropdown in the request page
  getAllLocations(): Observable<any> {
    return this.http.get(API_URL + 'v1/locations?page_size=5000', httpOptions);
  }

  
}