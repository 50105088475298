import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrl: './permission.component.scss'
})
export class PermissionComponent implements OnInit{
  id: string;
  name: string;
  address: string;
  gridData: any[];
  appInfo: any[];
  appNames = new Map<string, string>();
  email: string = ""


  colData = [
   
    { field: "appID", header: 'App ID' },
    { field: "appName", header: 'App Name' },
    { field: "canView", header: 'Can View' },
    { field: "canManage", header: 'Can Manage' },
    { field: "isApprover", header: 'Is Approver' }

  ]

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private service: AdminService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
      this.name = params['name']; // Retrieve the 'name' parameter
      this.address = params['address'];
    });
    this.getAppNames()
    .then (() => {
      this.getPermissions();
    })
    this.getPermissions();
  }

  // get access permissions for each loaction
  getPermissions(): void { 
    this.service.getEmployeePermissions(this.email).subscribe((res: any) => {
      this.gridData = res.locations;
      console.log("locations Data", this.gridData)
      let newObjects: {
        appName: string,
        appID: string, 
        canView: string, 
        canManage: string, 
        isApprover: string, 
      } [] = [];


      this.gridData.forEach((obj: any) => {
        this.appInfo = obj.apps;
        if (obj.location_id == this.id) {
          console.log("app info", this.appInfo);
          Object.keys(this.appInfo).forEach((appKey: any) => {
            const app = this.appInfo[appKey];
            console.log("app id", appKey);
            console.log("app name", this.appNames.get(appKey));
            let newObj = { 
              appName: this.appNames.get(appKey),
              appID: appKey,
              canView: app.can_view ? 'Yes' :'No' , 
              canManage: app.can_manage ? 'Yes' : 'No', 
              isApprover: app.is_approver ? 'Yes' : 'No', 
            };
            newObjects.push(newObj);
          });
        }
      });
      
      this.gridData = newObjects;
      console.log("Res", this.gridData)
    })
  }

  //  to get the app names and their id
  getAppNames(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service.findAppNames().subscribe((res: any) => {
        res.data.forEach(row => {
            const appID = row.AppId;
            const appName = row.Name;
            this.appNames.set( appID, appName );
        });
        resolve();
        console.log(this.appNames);
      });
    });
    
  }
 
}
