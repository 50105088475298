<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link"  [attr.aria-expanded]="tagsCollapsed"
        aria-controls="ui-basic" href="https://{{urlPrefix}}.marmonim.com" class="nav-link">
        <span class="menu-title">Apps</span>
        <i class="mdi mdi-apps menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <!-- <a class="nav-link" (click)="tagsCollapsed = !tagsCollapsed" [attr.aria-expanded]="tagsCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">Tags</span>
        <i class="mdi mdi-tag menu-icon"></i>
      </a> -->
      <div class="collapse" id="tags" [ngbCollapse]="!tagsCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" href="/account" routerLinkActive="active" #tagList="routerLinkActive">Profile</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/locations" routerLinkActive="active" #tagModuleAdd="routerLinkActive">Assigned Locations</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/employees" routerLinkActive="active" #tagModuleAdd="routerLinkActive">Employees</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/request/access" routerLinkActive="active" #tagModuleAdd="routerLinkActive">Request Access</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/pendingRequest" routerLinkActive="active" #tagModuleAdd="routerLinkActive">Pending Request</a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</nav>