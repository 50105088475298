import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AccountComponent } from './admin/profile/account.component';
import { LocationsComponent } from './admin/locations/locations.component';
import { EmployeesComponent } from './admin/employees/employees.component';
import { PermissionComponent } from './admin/permission/permission.component';
import { ApprovalComponent } from './admin/approval/approval.component';
import { RequestComponent } from './admin/request/request.component';
import { EmployeeProfileComponent } from'./admin/employee-profile/employee-profile.component';
import { CheckboxTreeComponent } from "./admin/checkbox-tree/checkbox-tree.component"

const routes: Routes = [

  { 
    path: 'login/callback', 
    component: OktaCallbackComponent 
  },
  { 
    path: 'account', 
    component:  AccountComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),    
  },
  { 
    path: 'locations', 
    component:  LocationsComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),        
  },
  { 
    path: 'locations/:id/:name/:address', 
    component:  PermissionComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),            
  },
  { 
    path: 'employees', 
    component:  EmployeesComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  },
  // { 
  //   path: 'employees/:id/permissions', 
  //   component:  EmployeePermissionsComponent,
  //   canActivate:[OktaAuthGuard],
  //   loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  // },
  { 
    path: 'employees/:id/:email', 
    component:  EmployeeProfileComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  },
  // { 
  //   path: 'employeePermissions', 
  //   component:  EmployeePermissionsComponent,
  //   canActivate:[OktaAuthGuard],
  //   loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  // },
  { 
    path: 'pendingRequest', 
    component:  ApprovalComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  },
  { 
    path: 'request/access', 
    component:  RequestComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  },
  { 
    path: 'request/access/:locationId/:appId', 
    component:  RequestComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule),     
  },
  { 
    path: 'test', 
    component:  CheckboxTreeComponent,
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule), 
  },

  { 
    path: '', 
    redirectTo: 'account',
    canActivate:[OktaAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(c => c.AdminModule), 
  }


  // { 
  //   path: 'list/add', 
  //   component:  TagsAddEditComponent,
  //   canActivate:[OktaAuthGuard],
  //   loadChildren: () => import('./tags/tags.module').then(c => c.TagsModule),    
  // },
  // { 
  //   path: 'list/edit/:id', 
  //   component:  TagsAddEditComponent,
  //   canActivate:[OktaAuthGuard],
  //   loadChildren: () => import('./tags/tags.module').then(c => c.TagsModule),    
  // },
  // { 
  //   path: 'list/view/:id', 
  //   component:  TagsViewComponent,
  //   canActivate:[OktaAuthGuard],
  //   loadChildren: () => import('./tags/tags.module').then(c => c.TagsModule),    
  // },
  // { 
  //   path: '', 
  //   redirectTo: '/list',
  //   canActivate:[OktaAuthGuard],
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes) ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
