<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox 
      class="checklist-leaf-node"
      [checked]="checklistSelection.isSelected(node)"
      (change)="toggleSelection(node)">
      {{ node.name }}
    </mat-checkbox>
  </mat-tree-node>
  
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle>
      <i class="mdi" [ngClass]="treeControl.isExpanded(node) ? 'mdi-chevron-down' : 'mdi-chevron-right'"></i>
    </button>
    <mat-checkbox 
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
      (change)="todoItemSelectionToggle(node)">
      {{ node.name }}
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>

<!-- Button to get the selected ids -->
<!-- <button (click)="getSelectedNodes()">Get Selected IDs</button> -->