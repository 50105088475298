<div class="page-header">
  <h3 class="page-title font">
    {{ firstName }} {{ lastName }} <small>permissions</small>
  </h3>
</div>

<div class="row">
  <div class="col-lg-12 grid-margin" style="display: block;">
    <div class="card">
      <div class="card-body">
       
        <div class="info-row">
          <h4 class="info-label"> User Name:</h4>
          <h4 class="info-value">{{ firstName }} {{ lastName }}</h4>
        </div>
        <div class="info-row">
          <h4 class="info-label"> Employee ID:</h4>
          <h4 class="info-value">{{ id }}</h4>
        </div>
        <div class="info-row" style="padding-bottom: 50px;">
          <h4 class="info-label">Email Address:</h4>
          <h4 class="info-value">{{ email }}</h4>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- displays permissions for each locations in table format -->
<ng-container *ngFor="let location of locations">
  <div class="row">
    <div class="col-lg-12 grid-margin" style="display: block;">
      <div class="card">
        <div class="card-body">
          <div class="">
            <p><b>({{ location.location_id}})</b> </p>
            <p> {{ location.company_name}}</p>
            <p>{{ location.location_address}} </p>

            <table class="table table-striped">
              <thead>
                <tr>
                  <th colspan="4">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Application Name</th>
                  <th>Can View</th>
                  <th>Can Manage</th>
                  <th>Can Approve</th>
                </tr>
              
                <tr *ngFor="let key of getObjectKeys(location.apps)">
                  <td>({{key}}) - {{ appNames.get(key) }}</td>
                  <td>Yes</td>
                  <td>{{location.apps[key]['can_manage'] == true ? "Yes" : "No"}}</td>
                  <td>{{location.apps[key]['is_approver'] == true ? "Yes" : "No"}}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>