<div class="">
    
    <table class="table table-striped">
        <thead >
            <tr>
                <th  *ngFor="let col of ColData">{{col.header}} </th>
                <th *ngIf="ViewUrl || DeleteUrl || EditUrl || DownloadUrl || ApproveUrl" colspan="3">Access Permissions</th>
            </tr>
        </thead>
        <tbody *ngIf="GridData !== undefined && GridData.length > 0">
            <tr *ngFor="let record of GridData  | paginate: {id: 'listing_pagination', itemsPerPage: pageSize, currentPage: page, totalItems: totalRecords };">
                <td *ngFor="let key of ColData">
                    <ng-container *ngIf="key.field == 'locationID'; else notID">
                        <a [href]="'https://sites.apps.marmonim.com/locations/' + record[key.field]">
                        {{ record[key.field] }}
                      </a></ng-container>
                      <ng-template #notID>
                        <ng-container *ngIf="key.field == 'employeeID'; else notemployeeID">
                            <a style="color: blue; cursor: pointer;" ngbTooltip="View" [id]="record.employeeID" (click)="navigateToEmployeeAccess(record.employeeID, record.email)">
                            {{ record[key.field] }}
                          </a>
                        </ng-container>
                    
                        <ng-template #notemployeeID>
                            {{record[key.field]}}
                        </ng-template>
                    </ng-template>
                </td>
                <td *ngIf="ViewUrl" [id]="record.locationID" (click)="navigateToChild(record.locationID, record.CompanyName, record.locationAddress)"> 
                    <!-- <a href={{ViewUrl+record.id}}> -->
                    <i ngbTooltip="View" class="mdi mdi-security"></i>
                    <!-- </a> -->
                </td>
                <td *ngIf="CopyUrl" [id]="record.id"><a href={{CopyUrl+record.id}}>
                    <i ngbTooltip="Copy" class="mdi mdi-content-copy"></i>
                </a></td>
                <td *ngIf="EditUrl">
                    <a  href={{EditUrl+record.id}}><i ngbTooltip="Edit" class="mdi mdi-pencil"></i>
                    </a>
                </td> 
                <td *ngIf="ViewUrl">
                    <a  href={{ViewUrl+record.id}}><i ngbTooltip="View" class="mdi mdi-eyel"></i>
                    </a>
                </td> 
                <td *ngIf="LocationUrl" [id]="record.id"><a href={{LocationUrl+record.id}}>
                    <i ngbTooltip="Locations" class="mdi mdi-google-maps menu-icon"></i>
                </a></td>
                <td *ngIf="AssetUrl" [id]="record.id"><a href={{AssetUrl+record.id}}>
                    <i ngbTooltip="Assets" class="mdi mdi-puzzle menu-icon"></i>
                </a></td>
            </tr>
        </tbody>

        <tr *ngIf="GridData !== undefined && GridData.length <= 0">
            <td style="text-align: center;" [attr.colspan]="ColData.length+1">
            No Records Found!
            </td>
        </tr>
        <br/>
    </table>
</div>